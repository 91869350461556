import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Slide } from 'react-slideshow-image';
import { ZonesData } from "../DropData";
import Selector from "../components/utils/DropDownSearch";

function Banner() {
  const [banner, setBanner] = useState<any | []>(null);
  const [url, setUrl] = useState<any | []>([]);
  const [selectedZone, setSelectedZone] = useState<string>('-');
  const [zones, setZones] = useState<any | []>([]);
  const [getSelectedZoneBanner, setSetSelectedZoneBanner] = useState<string>('-');

  const UpdateBanner = (e: any) => {
    const files = e.target.files;
    if (files && files[0]) {
      const myfiles: any = []
      for (const file of files) {
        myfiles.push({ url: window.URL.createObjectURL(file), file: file })
      }
      setUrl(myfiles);
    }
  };

  const getBanner = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    axios
      .get(`${baseUrl}banner?zone=${getSelectedZoneBanner}`, {
        headers: {
          authorization: token ? token : "",
        },
      })
      .then((res) => {
        setBanner(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteBanner = (id: any) => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    axios
      .post(`${baseUrl}bannerDelete/${id}`, {}, {
        headers: {
          authorization: token ? token : "",
        },
      })
      .then((res) => {
        toast.success("Successfully Deleted");
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadBanner = () => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    if (url && url.length && selectedZone !== '-') {
      for (const thisurl of url) {
        const fd = new FormData();
        fd.append("file", thisurl.file);
        fd.append("zone", selectedZone);

        axios
          .post(`${baseUrl}bannerUpload`, fd, {
            headers: {
              authorization: token ? token : "",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            toast.error("Failed to update");
          });
      }
      toast.success("Successfully Updated");
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    } else {
      toast.error("Please select the Zone and Banner.");
    }
  }

  useEffect(() => {
    getBanner();
    return () => { };
  }, [getSelectedZoneBanner]);

  const getzones = () => {
    setZones(Object.keys(ZonesData));
  };

  useEffect(() => {
    getzones();
    return () => { };
  }, []);

  return (
    <div>
      <div className="flex justify-center mt-8 flex-wrap">
        <div
          style={{
            width: "50%",
            height: "auto",
          }}
        >
          <div className="stat bg-white rounded-lg">
            <div className="stat-title">Zone</div>
            <select
              value={getSelectedZoneBanner}
              className="input input-bordered"
              onChange={(e: any) => {
                setSetSelectedZoneBanner(e.target.value);
              }}
            >
              <option value="-" selected disabled>Select Zone</option>
              {zones.map((item: any, key: any) => (
                <option key={key} value={item}>{item}</option>
              ))}
            </select>
          </div>
          {banner && banner.length ? (
            <Slide>
              {banner.map((item: any) => (
                <div className="each-slide-effect w-full h-full">
                  <button
                    onClick={() => deleteBanner(item.id)}
                    className="absolute px-4 py-2 text-white bg-primary rounded shadow-xl"
                  >
                    Delete
                  </button>
                  <img
                    src={process.env.REACT_APP_S3_URI + item.image}
                    className="rounded w-full h-full"
                  />
                </div>
              ))}
            </Slide>
          ) :
            <div className="h-50 text-center my-20">No data found</div>
          }
          {/* <img
              src={process.env.REACT_APP_S3_URI + banner.image}
              className="rounded w-full h-full"
            /> */}
        </div>
        <div className="rounded-lg shadow-xl bg-gray-50 lg:w-1/2">

          <div className="stat">
            <div className="stat-title">Zone</div>
            <select
              value={selectedZone}
              className="input input-bordered"
              onChange={(e: any) => {
                setSelectedZone(e.target.value);
              }}
            >
              <option value="-" selected disabled>Select Zone</option>
              {zones.map((item: any, key: any) => (
                <option key={key} value={item}>{item}</option>
              ))}
            </select>
          </div>

          <div className="m-4">
            <label className="inline-block mb-2 text-gray-500">
              Upload Image(jpg,png,jpeg)
            </label>

            <div className="flex flex-col w-full h-50 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
              {url && url.length > 0 &&
                <Slide>
                  {url.map((item: any) => (
                    <div className="each-slide-effect w-full h-full">
                      <img src={item.url} className="rounded w-full h-full" />
                    </div>
                  ))}
                </Slide>
              }
            </div>

            <div className="flex items-center justify-center w-full">
              <label className="flex flex-col w-full h-50 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                {/* <div className="flex flex-col items-center justify-center pt-7"> */}
                {/* {url && url.length ? (
                  <Slide>
                    {url.map((item: any) => (
                      <div className="each-slide-effect w-full h-full">
                        <img src={item.url} className="rounded w-full h-full" />
                      </div>
                    ))}
                  </Slide>
                ) : ( */}
                <div className="flex flex-col items-center justify-center pt-7">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      //   fill-rule="evenodd"
                      d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                    //   clip-rule="evenodd"
                    />
                  </svg>
                  <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                    Select a photo
                  </p>
                </div>
                {/* )} */}
                {/* </div> */}
                <input
                  onChange={UpdateBanner}
                  type="file"
                  className="opacity-0"
                  accept="image/*"
                  multiple
                />
              </label>
            </div>
          </div>
          <div className="flex p-2 space-x-4 justify-center">
            {/* <button className="px-4 py-2 text-white bg-red-500 rounded shadow-xl">
              Cannel
            </button> */}
            <button
              onClick={() => uploadBanner()}
              className="px-4 py-2 text-white bg-primary rounded shadow-xl"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Banner;
