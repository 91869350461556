import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter } from "react-router-dom"
import { Provider } from 'react-redux'
import { store } from './app/store'
import 'react-slideshow-image/dist/styles.css';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)


// root.render(<Provider store={store}>
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// </Provider>);



// root.render(<App tab="home" />);