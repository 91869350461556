import React, { useEffect, useState } from "react";
import { DummyUser } from "../../models/user";
import { SubmitHandler, useForm } from "react-hook-form";
import { useCreateDummyUserMutation } from "../../app/services/userData";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";
import { useGetAllNativesQuery } from "../../app/services/natives";
import Heading from "../utils/Heading";
import { nativeCount } from "../../utils/constants";

import { ZonesData } from "../../DropData";

import { Country, State, City } from "country-state-city";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";

type Props = {};

const Adduser = (props: Props) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<DummyUser>();
  const [createDummyUser, { isLoading }] = useCreateDummyUserMutation();
  const { isAdmin, user } = useAuth();
  const { data } = useGetAllNativesQuery({ take: nativeCount, skip: 0 });
  const [localurls, setLocalUrls] = useState<any | {}>({})
  const [urls, setUrls] = useState<any | {}>({});

  const onSubmit: SubmitHandler<DummyUser> = async (formData, e) => {
    try {
      if (formData.mobile && formData.mobile.length) {
        formData.mobile = "+91" + formData.mobile;
      }else{
        formData.mobile = null
      }
      const city = filters.city.toLowerCase();
      const area = filters.area.toLowerCase();
      formData.city = city.charAt(0).toUpperCase() + city.slice(1);
      formData.area = area.charAt(0).toUpperCase() + area.slice(1);
      if (urls.profileUrl) {
        formData.profilePhoto = urls.profileUrl;
      }
      if (urls.coverUrl) {
        formData.coverPhoto = urls.coverUrl;
      }
      await createDummyUser(formData).unwrap();
      toast.success("User Created Successfully");
      setUrls({})
      setLocalUrls({})
      reset();
    } catch (error) {
      toast.error((error as any).data.msg);
    }
  };

  const [filters, setFilters] = useState({
    city: "",
    area: "",
  });

  const [cities, setCities] = useState<any | []>([]);
  const [areas, setAreas] = useState<any | []>([]);
  const [zones, setZones] = useState<any | {}>(ZonesData);

  const UpdateImage = (e: any, folder: any) => {
    const files = e.target.files;
    if (files && files[0]) {
      uploadImage(files[0], folder);
      if (folder === 'user-profile-photo') {
        setLocalUrls({ ...localurls, profileUrl: window.URL.createObjectURL(files[0]), file: files[0] });
      }
      if (folder === 'coverPhoto') {
        setLocalUrls({ ...localurls, coverUrl: window.URL.createObjectURL(files[0]), file: files[0] });
      }
    }
  };

  const uploadImage = (file: any, folder: any) => {
    const baseUrl = process.env.REACT_APP_API;
    const token = localStorage.getItem("token");

    const fd = new FormData();
    fd.append("file", file);
    axios
      .post(`${baseUrl}uploadImage/${folder}`, fd, {
        headers: {
          authorization: token ? token : "",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (folder === 'user-profile-photo') {
          setUrls({ ...urls, profileUrl: res.data.data });
        }
        if (folder === 'coverPhoto') {
          setUrls({ ...urls, coverUrl: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCities = () => {
    setCities(Object.keys(ZonesData));
  };

  const getAreas = () => {
    setAreas(zones[filters.city]);
  };

  useEffect(() => {
    getCities();
    return () => { };
  }, []);

  useEffect(() => {
    getAreas();
    return () => { };
  }, [filters]);

  return (
    <div>
      <form
        className="form-control bg-gray-100 rounded-md p-12 max-w-md m-auto w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Heading title="Add User" />

        <label className="label">
          <span className="label-text">First Name *</span>
        </label>

        <input
          type="text"
          placeholder="First Name"
          className="input input-bordered"
          {...register("firstName", { required: "This is required." })}
        />

        <ErrorMessage
          errors={errors}
          name="firstName"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />
        <label className="label">
          <span className="label-text">Sur Name *</span>
        </label>

        <input
          type="text"
          placeholder="Sur Name"
          className="input input-bordered"
          {...register("lastName", { required: "This is required." })}
        />

        <ErrorMessage
          errors={errors}
          name="lastName"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />

        <label className="label">
          <span className="label-text">Gender *</span>
        </label>

        <select
          placeholder="Gender"
          className="select input-bordered"
          {...register("gender", { required: "This is required." })}
          required
        >
          <option>MALE</option>
          <option>FEMALE</option>
        </select>

        <ErrorMessage
          errors={errors}
          name="gender"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />

        {/* -------- Added By Vaagish ---------- */}

        <label className="label">
          <span className="label-text">DOB *</span>
        </label>

        <input
          type="date"
          placeholder="DOB"
          className="input input-bordered"
          min="1918-01-01"
          // max="2022-12-31"
          {...register("dob", {
            required: "This is required.",
            // valueAsDate: true,
          })}
          required
        />

        <ErrorMessage
          errors={errors}
          name="dob"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />

        <label className="label">
          <span className="label-text">Martial Status *</span>
        </label>

        <select
          placeholder="Martial Status"
          className="select input-bordered"
          {...register("maritalStatus", { required: "This is required." })}
        >
          <option>SINGLE</option>
          <option>MARRIED</option>
          <option>DIVORCED</option>
          <option>WIDOWED</option>
        </select>

        <ErrorMessage
          errors={errors}
          name="maritalStatus"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />

        {/* --------------XXXXXXXXXXXX---------- */}

        <label className="label">
          <span className="label-text">Mobile (optional)</span>
        </label>

        <input
          type="text"
          placeholder="Mobile"
          className="input input-bordered"
          {...register("mobile", {
            // required: "This is required.",
            pattern: {
              value: /^[0-9]{10}$/,
              message: "Mobile number should be 10 digits",
            },
          })}
        />

        <ErrorMessage
          errors={errors}
          name="mobile"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />

        {/* -------- By Vaagish ------- */}

        <div
          className="input-head-upload"
          style={{ width: "100%", marginTop: "30px", justifyContent: "center" }}
        >
          <div
            className="input-head"
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              border: "1px solid #00000050",
              padding: "10px 0px 15px 0px",
              borderRadius: "10px",
            }}
          >
            <label
              style={{
                marginBottom: "10px",
                textAlign: "center",
                padding: "5px 0px",
                fontWeight: "600",
              }}
            >
              Family Head
            </label>
            <input
              type="checkbox"
              style={{ marginLeft: "50%", textAlign: "center" }}
              {...register("isHeadOfHousehold")}
            />
          </div>

          <div
            className="input-upload"
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              border: "1px solid #00000050",
              padding: "10px 0px 15px 0px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <label
              htmlFor="upload"
              style={{
                marginBottom: "10px",
                textAlign: "center",
                padding: "5px 0px",
                fontWeight: "600",
              }}
            >
              Upload Photo (optional)
            </label>
            <input type="file" name="upload" style={{ paddingLeft: "20%" }} onChange={(e) => UpdateImage(e, 'user-profile-photo')} />
          </div>

          <div
            className="input-upload"
            style={{
              display: "flex",
              flexDirection: "column",
              background: "#fff",
              border: "1px solid #00000050",
              padding: "10px 0px 15px 0px",
              borderRadius: "10px",
              marginTop: "20px",
            }}
          >
            <label
              htmlFor="upload-cover"
              style={{
                marginBottom: "10px",
                textAlign: "center",
                padding: "5px 0px",
                fontWeight: "600",
              }}
            >
              Upload Cover Photo (optional)
            </label>
            <input type="file" name="upload-cover" style={{ paddingLeft: "20%" }} onChange={(e) => UpdateImage(e, 'coverPhoto')} />
          </div>
        </div>

        {/* ----------- New Drops ------------- */}

        <label className="label">
          <span className="label-text">Zone *</span>
        </label>

        <select
          className="select input-bordered"
          defaultValue={""}
          {...register("city", { required: "This is required." })}
          onChange={(e) => {
            const city = e.target.value;

            setFilters({
              ...filters,
              city: city,
            });
          }}
        >
          <option value={""} disabled>
            Select Zone
          </option>
          {cities?.map((item: any, key: any) => (
            <option key={key} value={item}>
              {item}
            </option>
          ))}
        </select>

        <ErrorMessage
          errors={errors}
          name="city"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />

        {areas && areas.length ? (
          <label className="label">
            <span className="label-text">Area</span>
          </label>
        ) : null}
        {areas && areas.length ? (
          <select
            className="select input-bordered"
            defaultValue={""}
            {...register("area", { required: "This is required." })}
            onChange={(e) => {
              const area = e.target.value;
              setFilters({
                ...filters,
                area: area,
              });
            }}
          >
            <option value={""} disabled>
              Select Area
            </option>
            {areas?.map((item: any) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        ) : null}

        <ErrorMessage
          errors={errors}
          name="area"
          render={({ message }) => {
            return <span className="italic text-red-300">{message}</span>;
          }}
        />

        <div className="form-control mt-6 ">
          <button
            className={`btn btn-primary ${isLoading ? "loading" : ""}`}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default Adduser;
